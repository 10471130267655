<template>
    <div>
        <v-title title="Fields Overview"></v-title>

        <title-box>
            <div class="row">
                <div class="col col-9">
                    <h1>Fields Overview</h1>
                </div>

                <div class="col col-3 text-right">
                    <mercur-button class="btn btn-raised btn-primary" :to="{ name: 'AddField' }">
                        + Add new field
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listFields')">
                <div class="mt-4">
                    <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                    <merchant-data-table
                        class="shadow"
                        :options="options"
                        :url="url"
                        ref="table"
                        @paginationSet="updatePagination"
                        :isNotApplicationBound="true">
                    </merchant-data-table>
                </div>
            </template>
            <span v-else>Not allowed to see this view</span>
        </div>
        <mercur-dialog :is-open.sync="dialog.isOpen">
            {{ dialog.content }}
            <template #footer>
                <mercur-button class="btn" @click="dialog.isOpen = false">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click="revoke">Revoke</mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'

export default {
    name: 'ConfigurationsOverview',
    components: { MerchantDataTable, Pagination },

    data () {
        return {
            url: CONFIG.API.ROUTES.PRODUCT_FEEDS.FIELDS.OVERVIEW,
            isDialogOpen: false,
            activeData: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            requiredSystemFields: ['productHash', 'sku'],
            dialog: {
                isOpen: false,
            },
            options: {
                columns: {
                    'Field Name': {
                        field: 'fieldName',
                        link: (value, data) => {
                            return {
                                name: 'EditField',
                                params: {
                                    fieldId: data.fieldId,
                                },
                            }
                        },
                    },
                    'Field Type': { field: 'fieldType', filter: false, sortable: true },
                    'Date Created': { field: 'dateCreated', filter: false },
                    'Date Updated': { field: 'dateUpdated', filter: false, sortable: true },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            to: ({ data }) => {
                                return {
                                    name: 'EditField',
                                    params: {
                                        fieldId: data.fieldId,
                                    },
                                }
                            },
                            isDisabled: ({ data }) => {
                                return this.requiredSystemFields.includes(data.fieldName) ||
                                    data.fieldType === 'SYSTEM'
                            },
                        },
                        {
                            text: 'Revoke',
                            onClick: ({ data }) => {
                                this.triggerRevokeDialog(data)
                            },
                            isDisabled: ({ data }) => {
                                return this.isLoading || this.requiredSystemFields.includes(data.fieldName) ||
                                    data.fieldType === 'SYSTEM'
                            },
                            isHidden: () => {
                                return !this.hasPermission('revokeFields')
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
            },
        }
    },
    methods: {
        triggerRevokeDialog (data) {
            this.activeData = data
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to revoke this field?`,
            }
        },
        revoke () {
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.FIELDS.REVOKE.replace('{fieldId}', this.activeData.fieldId)
            this.isLoading = true

            this.post(url, {}, 'Revoke success').then(() => {
                this.$refs.table.refreshGrid()
            })
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },

    },
}
</script>

<style lang="scss" scoped>
.c-configurations {
    padding: 50px;
}
</style>
