<template>
    <div class="c-default-table__pagination">
        <p class="c-default-table__pagination-info">Display
            <select v-model="amount" @change="changeAmount" class="c-default-table__pagination-amount">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            of {{ pagination.totalRows }} items, Page {{ pagination.currentPage }} of {{ pagination.totalPages }}</p>
        <mercur-button class="btn c-default-table__pagination-button" @click="toPreviousPage"><i class="fas fa-chevron-left mr-0"></i></mercur-button>
        <mercur-button class="btn c-default-table__pagination-button" @click="toNextPage"><i class="fas fa-chevron-right mr-0"></i></mercur-button>

        <mercur-button v-if="enableRefresh" class="btn c-default-table__pagination-button ml-1" @click="refresh"><i class="fa fa-rotate-right mr-0"></i></mercur-button>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    data () {
        return {
            amount: 50,
        }
    },
    props: {
        pagination: {
            required: true,
            default: {
                currentPage: 1,
                totalPages: 1,
                totalRows: 1,
                defaultAmount: 1,
            },
        },
        enableRefresh: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        pagination: {
            handler (value) {
                this.$set(this, 'amount', value.defaultAmount)
            },
            deep: true,
        },
    },
    methods: {
        changeAmount (event) {
            if (!event.target.value) {
                return
            }
            this.$emit('change', event.target.value)
        },
        toPreviousPage () {
            if (this.pagination.totalPages > 1) {
                this.$parent.$refs.table.toPreviousPage()
            }
        },
        toNextPage () {
            if (this.pagination.totalPages > 1) {
                this.$parent.$refs.table.toNextPage()
            }
        },
        refresh () {
            this.$parent.$refs.table.refreshGrid()
        },
    },
}
</script>
